import React, { useState, useEffect, useRef } from 'react';
import { ProgressBar } from 'primereact/progressbar';
import { Toast } from 'primereact/toast';
import './modal-progress.scss'

export const ModalProgress = () => {
    const [value1, setValue1] = useState(0);
    const toast = useRef(null);
    const interval = useRef(null);

    const displayValueTemplate = (value) => {
        return (
            <React.Fragment>
                {value}/<b>100</b>
            </React.Fragment>
        );
    }

    useEffect(() => {
        let val = value1;
        interval.current = setInterval(() => {
            val += Math.floor(Math.random() * 10) + 1;

            if (val >= 100) {
                val = 100;
                toast.current.show({ severity: 'info', summary: 'Success', detail: 'Process Completed' });
                clearInterval(interval.current);
            }

            setValue1(val);
        }, 2000);

        return () => {
            if (interval.current) {
                clearInterval(interval.current);
                interval.current = null;
            }
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div class="progress-container">
          <div className="p-col">
              <Toast ref={toast}></Toast>
              <div className="card">
                  <ProgressBar value={25}></ProgressBar>
              </div>
              <div className="p-grid prog-marks">
                <h3 className="p-col prog-title"  style={{display: 'none'}}>
                 </h3>
                <h3 className="p-col prog-title">
                  Promote
                </h3>
                <h3 className="p-col prog-title">
                  Create
                </h3>
                <h3 className="p-col prog-title">
                  Confirm
                </h3>
                <h3 className="p-col prog-title">
                  Checkout
                </h3>
              </div>
          </div>
        </div>
    );
}
// export default ModalProgress;
