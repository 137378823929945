import React, { useState, useEffect, useRef } from 'react';

import "./modal-header.scss"

export const ModalHeader = () => {
    return (
      <div className="modal-header">
        <table class="styled-table">
        <tbody>
         <tr>
            <td className="img-cont">
              <div className="img-org">
                <img className="mod-main-img"  src="assets\layout\images\car.jpg" />
              </div>
              <div className="img-text">
                <h2 className="img-text-cont">Streaming</h2>
                <p className="img-text-cont">
                  Budget: <strong>500</strong>
                </p>
              </div>
            </td>
            <td className="mid-cont">
              <p className="box-text1">
                Impressions:
              </p>
              <p className="box-text1">
                Views:
              </p>
              <p className="box-text1">
                VTR:
              </p>
            </td>
            <td className="low-cont">
              <p className="box-text">
                <strong>500</strong>
              </p>
              <p className="box-text">
                <strong>500</strong>
              </p>
              <p className="box-text">
                <strong>500</strong>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      </div>
      );
  }
