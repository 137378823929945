import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

import { Dropdown } from 'primereact/dropdown';
import './table-filter.scss'

export const TableFilter = () => {

  const [selectedCity1, setSelectedCity1] = useState(null);

    const cities = [
        { name: 'New York', code: 'NY' },
        { name: 'Rome', code: 'RM' },
        { name: 'London', code: 'LDN' },
        { name: 'Istanbul', code: 'IST' },
        { name: 'Paris', code: 'PRS' }
    ];

  

    const onCityChange = (e) => {
        setSelectedCity1(e.value);
    }
  

    return (
    <div className="p-grid gs-container2 center hh bb  center-pad">
        <div class="w-small p-col">
           <Dropdown value={selectedCity1} options={cities} onChange={onCityChange} optionLabel="name" placeholder="Filter" />
        </div>

        <div class=" w-small p-col center">
        <span className="p-input-icon-left p-input-icon-right">
            <InputText type="text" placeholder="Search" />
            <i className="pi pi-search" />
        </span>
        </div>

        <div class="w-small f-right p-col">
          <h5><strong>0 Results</strong></h5>
        </div>

    </div>
    );
}
