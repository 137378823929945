import React, { Component } from "react";
import { Panel } from 'primereact/panel';
import { Linegraph } from '../../components/dashboard/linegraph/linegraph'
import { Results } from '../../components/dashboard/results/results'
import { LiveFilter } from '../../_GScomponents/live-filter/live-filter'
import { BasicButtonRow } from '../../_GScomponents/basic-button-row/basic-button-row'
import './l-dashboard.scss'
// import { Inventory } from '../../components/dashboard/inventory/inventory'


export const LDashboard = () => {
  {
     return (
       <div className="p-grid dashboard-container" style={{height: '50%'}}>
         <Panel header="Publishers" style={{height: '100%'}} className="p-col navstyle2">
           <BasicButtonRow/>
           <Results/> 
          </Panel>
         <Panel  style={{height: '100%'}} className="p-col-7 mainstyle">
            <LiveFilter/>
            <Linegraph/>
         </Panel>
         <Panel header="Top Performing" style={{height: '100%'}} className="p-col">
           {/* <Inventory/> */}
         </Panel>
       </div>
       );
   }
 } 
