import React from "react";
// import { CampNav } from "../../components/camp-nav/camp-nav";
import { CampMain } from "../../components/camp-main/camp-main";
import { CampSide } from "../../components/camp-side/camp-side";
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import './campaign.scss'


export const Campaign = () => {
 {
    return (
      <div className="camp-layout p-grid p-align-stretch vertical-container" style={{height: '100%'}}>
        <div className="side-nav-main p-col-2">
          <div className="cam-title p-col">
            <Button label="Audience" icon="pi pi-times" className="p-button-rounded p-button-danger p-button-text" iconPos="right" />
          </div>
          {/* <CampNav/> */}
        </div>
        <Card style={{height: '100%'}} className="p-col-7">
           <CampMain/>
        </Card>
        <div className="p-col">
           <CampSide/>
        </div>
      </div>
      );
  }
}

