import React, { Component } from "react";
import { Panel } from 'primereact/panel';
import { Dgraph } from '../../components/dashboard/dgraph/dgraph'
// import { Results } from '../../components/dashboard/results/results'
import { TableFilter } from '../../_GScomponents/table-filter/table-filter'
import { Inventory } from '../../components/dashboard/inventory/inventory'
import { Cart } from '../../components/dashboard/cart/cart'
import './m-dashboard.scss'

export const MDashboard = () => {
  {
     return (
       <div className="p-grid dashboard-container" style={{height: '50%'}}>
         <Panel header="Operations" style={{height: '100%'}} className="p-col navstyle">
           <Dgraph/>
            {/* <Results/>  */}
          </Panel>
         <Panel header="Your Inventory" style={{height: '100%'}} className="p-col-7 mainstyle">
            <TableFilter/>
            <Inventory/>
         </Panel>
         <Panel header="Your Cart" style={{height: '100%'}} className="p-col">
           <Cart/>
         </Panel>
       </div>
       );
   }
 }
