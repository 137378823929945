import React, { Component } from "react";
import {useParams} from "react-router-dom";
import { Button } from 'primereact/button';


  export const Camp1 = () => {
    {
      let { topicId } = useParams();

       return (
        <div>
         <h1>Vehicles to Promote</h1>
         <div>
          <Button label="Save" />
          <Button label="Save" />
         </div>

        </div>
         );
     }
   }
  
