import React, { useState, useEffect } from 'react';
// import { DataTable } from 'primereact/datatable';
// import { Column } from 'primereact/column';
import ProductService from '../../../service/ProductService';
import './results.scss'

export const Results = () => {
    const [products, setProducts] = useState([]);
    const productService = new ProductService();

    useEffect(() => {
        productService.getProductsSmall().then(data => setProducts(data));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
      {/* <table class="styled-table">
        <tbody>
          <tr>
            <td>
              <h1>New</h1>
            </td>
            <td className="float-left  ">
              <p>
                Avg Turn Rate: <strong className="ecxerpt">43 Days</strong>
              </p>
              <p>
                Total in-stock: <strong className="ecxerpt">270</strong>
              </p>
            </td>
          </tr>
          <tr class="active-row ">
            <td className="shrink">
              <h2>Toyota Camry</h2>
              <p>
                Avg-Stock: <strong className="ecxerpt">15</strong>
              </p>
            </td>
            <td className="float-left shrink">
              <h2 className="ecxerpt2">15 in Stock</h2>
            </td>
          </tr>
          <tr class="active-row ">
            <td className="shrink">
              <h2>Toyota Tacoma</h2>
              <p>
                Avg-Stock: <strong className="ecxerpt">15</strong>
              </p>
            </td>
            <td className="float-left shrink">
              <h2 className="ecxerpt2">57 Days</h2>
            </td>
          </tr>
          <tr >
            <td>
              <h1>Used</h1>
            </td>
            <td className="float-left">
              <p>
                   Avg Turn Rate:e <strong className="ecxerpt">DATA</strong>
              </p>
              <p>
                    Total in-stock: <strong className="ecxerpt">DATA</strong>
              </p>
            </td>
          </tr>
          <tr class="active-row ">
            <td className="shrink">
              <h2>CPO</h2>
              <p>
                Avg-Stock: <strong className="ecxerpt">15</strong>
              </p>
            </td>
            <td className="float-left shrink">
              <h2 className="ecxerpt2">21 days<spam className="pi pi-exclamation-triangle"></spam> </h2>
              <p>
                Your time has <br /> exceeded your goal.
              </p>
            </td>
          </tr>
          <tr class="active-row ">
            <td className="shrink">
              <h2>Used</h2>
              <p>
                In Stock: <strong className="ecxerpt">1</strong>
              </p>
              <p>
                Avg-Stock: <strong className="ecxerpt">15</strong>
              </p>
            </td>
            <td className="float-left shrink">
              <h2 className="ecxerpt2">14 Days</h2>
            </td>
          </tr>
        </tbody>
      </table> */}
       <table class="styled-table">
        <tbody>
         <tr>
            <td className="img-cont">
              <div className="img-org">
                <img className="main-img"  src="assets\layout\images\youtube.jpg" />
              </div>
              <div className="img-text">
                <h2 className="img-text-cont">Streaming</h2>
                <p className="img-text-cont">
                  Budget: <strong>500</strong>
                </p>
              </div>
            </td>
            <td className="mid-cont">
              <p className="box-text1">
                Impressions:
              </p>
              <p className="box-text1">
                Views:
              </p>
              <p className="box-text1">
                VTR:
              </p>
            </td>
            <td className="low-cont">
              <p className="box-text">
                <strong>500</strong>
              </p>
              <p className="box-text">
                <strong>500</strong>
              </p>
              <p className="box-text">
                <strong>500</strong>
              </p>
            </td>
          </tr>
        <tr>
            <td className="img-cont">
              <div className="img-org">
              <img className="main-img"  src="assets\layout\images\wifi.png" />
              </div>
              <div className="img-text">
                <h2 className="img-text-cont">Streaming</h2>
                <p className="img-text-cont">
                  Budget: <strong>500</strong>
                </p>
              </div>
            </td>
            <td className="mid-cont">
              <p className="box-text1">
                Impressions:
              </p>
              <p className="box-text1">
                Views:
              </p>
              <p className="box-text1">
                VTR:
              </p>
            </td>
            <td className="low-cont">
              <p className="box-text">
                <strong>500</strong>
              </p>
              <p className="box-text">
                <strong>500</strong>
              </p>
              <p className="box-text">
                <strong>500</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td className="img-cont">
              <div className="img-org">
                <img className="main-img" src="assets\layout\images\facebook.png" />
              </div>
              <div className="img-text">
                <h2 className="img-text-cont">Streaming</h2>
                <p className="img-text-cont">
                  Budget: <strong>500</strong>
                </p>
              </div>
            </td>
            <td className="mid-cont">
              <p className="box-text1">
                Impressions:
              </p>
              <p className="box-text1">
                Views:
              </p>
              <p className="box-text1">
                VTR:
              </p>
            </td>
            <td className="low-cont">
              <p className="box-text">
                <strong>500</strong>
              </p>
              <p className="box-text">
                <strong>500</strong>
              </p>
              <p className="box-text">
                <strong>500</strong>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    );
}
     