import axios from 'axios';

export class ProductService {
    getProductsWithcar() {
        return axios.get('assets/demo/data/car_data.json').then(res => res.data.data);
    }

    getProductsSmall() {
        return axios.get('assets/demo/data/products-small.json').then(res => res.data.data);
    }

    getProducts() {
        return axios.get('assets/demo/data/products.json').then(res => res.data.data);
    }

    getProductsWithOrdersSmall() {
        return axios.get('assets/demo/data/products-orders-small.json').then(res => res.data.data);
    }
}

export default ProductService;