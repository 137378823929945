import React, { Component } from "react";
import { CampSteps } from '../camp-steps/camp-steps';
import { Button } from 'primereact/button';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch
} from "react-router-dom";
import { Camp1 } from './camp-pages/camp1/camp1'
import { Camp2 } from './camp-pages/camp2/camp2'
import './camp-main.scss'




// import { Panel } from 'primereact/panel';
// import { CampNav } from "../../components/camp-nav/camp-nav";

  export const CampMain = () => {
    {
      let { path, url } = useRouteMatch();
      // let { topicId } = useParams();
       return (
          <div className="parent-main" >
            <div className="top-sec p-col-12">
              {/* <h2>top section</h2> */}
              <CampSteps className="bot-sec-steps"/>
            </div>
            <div className="main-sec p-col-12 p-mr-2 p-as-stretch">
              <div>
                {/* <h1>Simple SPA</h1> */}
                <Route
                  render={({ match: { url } }) => (
                  <>
                  <Route exact path={`${url}/`} />
                  <Route path={`${url}/1`} component={Camp1} />
                  <Route path={`${url}/2`} component={Camp2} />
                </>
                )}
                />

              </div>
            </div>
            <div className="bottom-sec p-col-12">
              <div className="bottom-sec-container">
                <Button className="bot-sec-button" label="Save" />
                {/* <CampSteps className="bot-sec-steps"/> */}
              </div>
            </div >
          </div>
         );
     }
   }
  
