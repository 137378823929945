import React from 'react';
import { ResponsiveLine } from '@nivo/line'
import { motion } from "framer-motion"

// import data from './dgraph.json'


let data =[
	{
	  "id": "japan",
	  "color": "hsl(1, 70%, 50%)",
	  "data": [
		{
		  "x": "plane",
		  "y": 255
		},
		{
		  "x": "helicopter",
		  "y": 127
		},
		{
		  "x": "boat",
		  "y": 234
		},
		{
		  "x": "train",
		  "y": 200
		},
		{
		  "x": "subway",
		  "y": 212
		},
		{
		  "x": "bus",
		  "y": 214
		},
		{
		  "x": "car",
		  "y": 29
		},
		{
		  "x": "moto",
		  "y": 185
		},
		{
		  "x": "bicycle",
		  "y": 110
		},
		{
		  "x": "horse",
		  "y": 60
		},
		{
		  "x": "skateboard",
		  "y": 188
		},
		{
		  "x": "others",
		  "y": 263
		}
	  ]
	},
	{
	  "id": "france",
	  "color": "hsl(47, 70%, 50%)",
	  "data": [
		{
		  "x": "plane",
		  "y": 42
		},
		{
		  "x": "helicopter",
		  "y": 115
		},
		{
		  "x": "boat",
		  "y": 89
		},
		{
		  "x": "train",
		  "y": 236
		},
		{
		  "x": "subway",
		  "y": 49
		},
		{
		  "x": "bus",
		  "y": 78
		},
		{
		  "x": "car",
		  "y": 21
		},
		{
		  "x": "moto",
		  "y": 61
		},
		{
		  "x": "bicycle",
		  "y": 19
		},
		{
		  "x": "horse",
		  "y": 94
		},
		{
		  "x": "skateboard",
		  "y": 203
		},
		{
		  "x": "others",
		  "y": 197
		}
	  ]
	},
	{
	  "id": "us",
	  "color": "hsl(171, 70%, 50%)",
	  "data": [
		{
		  "x": "plane",
		  "y": 31
		},
		{
		  "x": "helicopter",
		  "y": 158
		},
		{
		  "x": "boat",
		  "y": 11
		},
		{
		  "x": "train",
		  "y": 225
		},
		{
		  "x": "subway",
		  "y": 44
		},
		{
		  "x": "bus",
		  "y": 150
		},
		{
		  "x": "car",
		  "y": 15
		},
		{
		  "x": "moto",
		  "y": 277
		},
		{
		  "x": "bicycle",
		  "y": 261
		},
		{
		  "x": "horse",
		  "y": 97
		},
		{
		  "x": "skateboard",
		  "y": 129
		},
		{
		  "x": "others",
		  "y": 72
		}
	  ]
	},
	{
	  "id": "germany",
	  "color": "hsl(177, 70%, 50%)",
	  "data": [
		{
		  "x": "plane",
		  "y": 204
		},
		{
		  "x": "helicopter",
		  "y": 250
		},
		{
		  "x": "boat",
		  "y": 183
		},
		{
		  "x": "train",
		  "y": 211
		},
		{
		  "x": "subway",
		  "y": 159
		},
		{
		  "x": "bus",
		  "y": 244
		},
		{
		  "x": "car",
		  "y": 190
		},
		{
		  "x": "moto",
		  "y": 128
		},
		{
		  "x": "bicycle",
		  "y": 269
		},
		{
		  "x": "horse",
		  "y": 13
		},
		{
		  "x": "skateboard",
		  "y": 42
		},
		{
		  "x": "others",
		  "y": 294
		}
	  ]
	},
	{
	  "id": "norway",
	  "color": "hsl(42, 70%, 50%)",
	  "data": [
		{
		  "x": "plane",
		  "y": 253
		},
		{
		  "x": "helicopter",
		  "y": 201
		},
		{
		  "x": "boat",
		  "y": 91
		},
		{
		  "x": "train",
		  "y": 243
		},
		{
		  "x": "subway",
		  "y": 52
		},
		{
		  "x": "bus",
		  "y": 7
		},
		{
		  "x": "car",
		  "y": 91
		},
		{
		  "x": "moto",
		  "y": 256
		},
		{
		  "x": "bicycle",
		  "y": 80
		},
		{
		  "x": "horse",
		  "y": 253
		},
		{
		  "x": "skateboard",
		  "y": 85
		},
		{
		  "x": "others",
		  "y": 131
		}
	  ]
	}
  ];

export const Linegraph =  () => {
	
	return  (
	<motion.div 
	animate={{ opacity: 5 }}
    transition={{ duration: 4, type: "tween" }}
	className="op p-grid"
	style={{height: '90%'}} >
		{/* <div className="circle"></div> */}
		<ResponsiveLine
        data={data}
        margin={{ top: 40, right: 20, bottom: 50, left: 60 }}
        xScale={{ type: 'point' }}
        yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
        yFormat=" >-.2f"
        axisTop={null}
        axisRight={null}
        axisBottom={{
            orient: 'bottom',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'transportation',
            legendOffset: 36,
            legendPosition: 'middle'
        }}
        axisLeft={{
            orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'count',
            legendOffset: -40,
            legendPosition: 'middle'
        }}
        colors={{ scheme: 'set1' }}
        pointSize={10}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-12}
		pointBorderWidth={12}
        useMesh={true}
		borderColor={{
			from: 'red_grey',
			modifiers: [
				['darker', .6],
				['opacity', .5]
			]
		}}
        legends={[
            {
                anchor: 'top',
                direction: 'row',
                justify: false,
                translateX: 61,
                translateY: -32,
                itemsSpacing: 9,
                itemDirection: 'left-to-right',
                itemWidth: 143,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 17,
                symbolShape: 'circle',
                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemBackground: 'rgba(0, 0, 0, .03)',
                            itemOpacity: 1
                        }
                    }
                ]
            }
        ]}
    />
		</motion.div>
	);

}

