import React, { useState, useEffect, useRef } from 'react';
import { Panel } from 'primereact/panel';
import { Checkbox } from 'primereact/checkbox';




import "./modal-base.scss"



export const ModalBase = () => {
  const [checked, setChecked] = useState(false);

    return (
        <Panel header="Choose a Media Package"  className="modal-base-wrapper">
          <div className="modal-base">
          <table class="styled-table">
        <tbody>
         <tr>
            <td className="img-cont p-grid">
              <div className="p-col-2 p-field-checkbox img-check">
                <Checkbox inputId="4" checked={checked} onChange={e => setChecked(e.checked)} />
                {/* <label htmlFor="binary">{checked ? 'true' : 'false'}</label> */}
              </div>
              <div className="p-co3 img-org">
                <img className="main-img"  src="assets\layout\images\cruise.png" />
              </div>
              <div className="p-col img-text">
                <h2 className="img-text-cont">Streaming</h2>
                <p className="img-text-cont">
                  Budget: <strong>500</strong>
                </p>
              </div>
            </td>
            <td className="mid-cont">
              <p className="box-text1">
                $1,500.00
              </p>
              <i className="modal-i pi pi-facebook" style={{'fontSize': '2em'}}></i>
              <i className="modal-i pi pi-facebook" style={{'fontSize': '2em'}}></i>
              <i className="modal-i pi pi-facebook" style={{'fontSize': '2em'}}></i>
            </td>
          </tr>
          <tr>
            <td className="img-cont p-grid">
              <div className="p-col-2 p-field-checkbox img-check">
                <Checkbox inputId="3" checked={checked} onChange={e => setChecked(e.checked)} />
                {/* <label htmlFor="binary">{checked ? 'true' : 'false'}</label> */}
              </div>
              <div className="p-co3 img-org">
                <img className="main-img"  src="assets\layout\images\atom.png" />
              </div>
              <div className="p-col img-text">
                <h2 className="img-text-cont">Streaming</h2>
                <p className="img-text-cont">
                  Budget: <strong>500</strong>
                </p>
              </div>
            </td>
            <td className="mid-cont">
              <p className="box-text1">
                $1,500.00
              </p>
              <i className="modal-i pi pi-facebook" style={{'fontSize': '2em'}}></i>
              <i className="modal-i pi pi-facebook" style={{'fontSize': '2em'}}></i>
              <i className="modal-i pi pi-facebook" style={{'fontSize': '2em'}}></i>
            </td>
          </tr>
          <tr>
            <td className="img-cont p-grid">
              <div className="p-col-2 p-field-checkbox img-check">
                <Checkbox inputId="2" checked={checked} onChange={e => setChecked(e.checked)} />
                {/* <label htmlFor="binary">{checked ? 'true' : 'false'}</label> */}
              </div>
              <div className="p-co3 img-org">
                <img className="main-img"  src="assets\layout\images\turb-atom.png" />
              </div>
              <div className="p-col img-text">
                <h2 className="img-text-cont">Streaming</h2>
                <p className="img-text-cont">
                  Budget: <strong>500</strong>
                </p>
              </div>
            </td>
            <td className="mid-cont">
              <p className="box-text1">
                $1,500.00
              </p>
              <i className="modal-i pi pi-facebook" style={{'fontSize': '2em'}}></i>
              <i className="modal-i pi pi-facebook" style={{'fontSize': '2em'}}></i>
              <i className="modal-i pi pi-facebook" style={{'fontSize': '2em'}}></i>
            </td>
          </tr>
          <tr>
            <td className="img-cont p-grid">
              <div className="p-col-2 p-field-checkbox img-check">
                <Checkbox inputId="1" checked={checked} onChange={e => setChecked(e.checked)} />
                {/* <label htmlFor="binary">{checked ? 'true' : 'false'}</label> */}
              </div>
              <div className="p-co3 img-org">
                <img className="main-img"  src="assets\layout\images\manual.png" />
              </div>
              <div className="p-col img-text">
                <h2 className="img-text-cont">Streaming</h2>
                <p className="img-text-cont">
                  Budget: <strong>500</strong>
                </p>
              </div>
            </td>
            <td className="mid-cont">
              <p className="box-text1">
                $1,500.00
              </p>
              <i className="modal-i pi pi-facebook" style={{'fontSize': '2em'}}></i>
              <i className="modal-i pi pi-facebook" style={{'fontSize': '2em'}}></i>
              <i className="modal-i pi pi-facebook" style={{'fontSize': '2em'}}></i>
            </td>
          </tr>
        </tbody>
      </table>

          </div>
        </Panel>   
      );
  }


// export default ModalBase;
