import React, { useState, useEffect } from 'react';
import { ToggleButton } from 'primereact/togglebutton';
import { Dropdown } from 'primereact/dropdown';
import './live-filter.scss'

export const LiveFilter = () => {

  const [selectedCity1, setSelectedCity1] = useState(null);
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);

    const cities = [
        { name: 'New York', code: 'NY' },
        { name: 'Rome', code: 'RM' },
        { name: 'London', code: 'LDN' },
        { name: 'Istanbul', code: 'IST' },
        { name: 'Paris', code: 'PRS' }
    ];

    const onCityChange = (e) => {
        setSelectedCity1(e.value);
    }


  

    return (
    <div className="p-grid gs-container1 center hh bb bt center-pad">
        <div class="w-small pcol">
          <h1>LIVE Monitoring</h1>
        </div>

        <div class=" w-small pcol center">
          <ToggleButton  className="mid-sec-but p-col space" checked={checked1} onChange={(e) => setChecked1(e.value)} onIcon="pi pi-check" onLabel="SHEDULE" offLabel="SHEDULE"/>
          <ToggleButton  className="mid-sec-but p-col space" checked={checked2} onChange={(e) => setChecked2(e.value)} onLabel="PERFORMANCE" offLabel="PERFORMANCE" onIcon="pi pi-check" style={{width: '10em'}} />
        </div>

        <div class="w-small f-right pcol">
            <Dropdown value={selectedCity1} options={cities} onChange={onCityChange} optionLabel="name" placeholder="Filter" />
        </div>

    </div>
    );
}
     


