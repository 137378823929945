import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
//import * as serviceWorker from './serviceWorker';
import { HashRouter } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';


import ProductsContextProvider from './contexts/ProductsContext';
// import CartContextProvider from './contexts/CartContext';
// import { Provider } from 'react-redux'
// import store from './redux/store/store'
// Redux Library Packages
// import { Provider } from “react-redux”;
// import createSagaMiddleware from ‘redux-saga’;
// import { createStore, applyMiddleware, compose } from “redux”;
// import { logger } from ‘redux-logger’;
// Custom redux Elements
// import rootReducer from “./redux/reducers/index”;
// import rootSaga from “./redux/sagas/index”;

ReactDOM.render(
    // <Provider store={store}>
            <ProductsContextProvider>
                <HashRouter>
                    <ScrollToTop>
                        <App></App>
                    </ScrollToTop>
                </HashRouter>
            </ProductsContextProvider>,
    // </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();