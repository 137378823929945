import React, { Component } from "react";
import ReactDOM from 'react-dom';
import { ModalProgress } from '../modal-progress/modal-progress'
import { ModalHeader } from '../modal-header/modal-header'
import { ModalBase } from '../modal-base/modal-base'
import { ModalFooter } from '../modal-footer/modal-footer'
import { ModalRouter } from '../modalrouter'
import { Panel } from 'primereact/panel';
import { Dgraph } from '../../dashboard/dgraph/dgraph'
import  Slider  from '../../slider/slider'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch
} from "react-router-dom";

import './modal.scss'

export const Modal = ({ isShowing, hide }) => isShowing ? ReactDOM.createPortal(
  <React.Fragment>
    <div className="modal-overlay"/>
    <div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog">
      <button type="button" className="modal-close-button" data-dismiss="modal" aria-label="Close" onClick={hide}>
        <span aria-hidden="true">&times;</span>
      </button>
      <Panel header="Promote Your Inventory" style={{height: '100%'}}  className="modal">
      <ModalHeader/>
      <ModalProgress/>
        {/* <ModalRouter/> */}
        <ModalBase/>
        <ModalFooter/> 
      </Panel>
    </div>
  </React.Fragment>, document.body
) : null;

// return (
//   <div></div>
// )