import React, { Component } from "react";
import {useParams} from "react-router-dom";

  export const Camp2 = () => {
    {
      // let { topicId } = useParams();

       return (
        <div>
         <h3>cheese</h3>
        </div>
         );
     }
   }
  
