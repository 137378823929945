import React, { Component } from "react";
import './cart.scss'

export const Cart = () => {
  {
     return (
      <div>
      <div className="head">
        <h1 className="head-tit">Summary</h1>
      </div>
      <div className="body">
        <div>
          {/* <h2 className="fir-bod">Nothing in your cart</h2> */}
        </div>
        <div>
          {/* <h2 className="sec-bod">Channels to Promote</h2> */}
        </div>
      </div>
      <div className="footer">
        <h1 className="footer-total">Total:</h1>
        <h1 className="footer-price">$0.00</h1>
      </div>
    </div>
       );
   }
 }
export default Cart;
