import React from 'react';
import { ResponsivePie } from '@nivo/pie'
import { motion } from "framer-motion"
import CountUp from 'react-countup';

// import data from './dgraph.json'


import './dgraphs.scss'

let data = [
	{
	  "id": "sass",
	  "label": "CHEVY",
	  "value": 394,
	  "color": "hsl(273, 70%, 50%)"
	},
	{
	  "id": "python",
	  "label": "HONDA",
	  "value": 20,
	  "color": "hsl(267, 70%, 50%)"
	},
	{
	  "id": "rust",
	  "label": "BMW",
	  "value": 543,
	  "color": "hsl(209, 70%, 50%)"
	},
	{
	  "id": "c",
	  "label": "TOYOTA",
	  "value": 104,
	  "color": "hsl(114, 70%, 50%)"
	},
	{
	  "id": "php",
	  "label": "MERCEDES",
	  "value": 142,
	  "color": "hsl(328, 70%, 50%)"
	}
  ];

export const Dgraph =  () => {
	
	return  (
	<motion.div
    animate={{ opacity: 5 }}
    transition={{ duration: 4, type: "tween" }}
    className="p-grid dgraph"
    style={{height: '85%'}} >
		<div className="circle">
            <div class="counter-wrap">
                <div className="counter">
                    <CountUp
                    end={173}
                    duration={50.75}
                    separator=" "
                    // suffix=" IN STOCK"
                    onEnd={() => console.log('Ended! 👏')}
                    onStart={() => console.log('Started! 💨')}
                    />
                    <span className="count-label" >IN STOCK</span>
                </div>
            </div>
        </div>
        <ResponsivePie
        data={data}
        margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
        startAngle={-180}
        innerRadius={0.75}
        padAngle={7}
        colors={{ scheme: 'category10' }}
        borderColor={{ from: 'color', modifiers: [ [ 'brighter', '3' ] ] }}
        radialLabelsSkipAngle={10}
        radialLabelsTextXOffset={21}
        radialLabelsTextColor="#333333"
        radialLabelsLinkOffset={-3}
        radialLabelsLinkStrokeWidth={6}
        radialLabelsLinkColor={{ from: 'color' }}
        sliceLabelsSkipAngle={10}
        sliceLabelsTextColor="#333333"
        defs={[
            {
                id: 'dots',
                type: 'patternDots',
                background: 'inherit',
                color: 'rgba(255, 255, 255, 0.3)',
                size: 4,
                padding: 1,
                stagger: true
            },
            {
                id: 'lines',
                type: 'patternLines',
                background: 'inherit',
                color: 'rgba(255, 255, 255, 0.3)',
                rotation: -45,
                lineWidth: 6,
                spacing: 10
            }
        ]}
        fill={[
            {
                match: {
                    id: 'ruby'
                },
                id: 'dots'
            },
            {
                match: {
                    id: 'c'
                },
                id: 'dots'
            },
            {
                match: {
                    id: 'go'
                },
                id: 'dots'
            },
            {
                match: {
                    id: 'python'
                },
                id: 'dots'
            },
            {
                match: {
                    id: 'scala'
                },
                id: 'lines'
            },
            {
                match: {
                    id: 'lisp'
                },
                id: 'lines'
            },
            {
                match: {
                    id: 'elixir'
                },
                id: 'lines'
            },
            {
                match: {
                    id: 'javascript'
                },
                id: 'lines'
            }
        ]}
        legends={[
            {
                anchor: 'bottom',
                direction: 'row',
                justify: false,
                translateX: 0,
                translateY: 44,
                itemsSpacing: 0,
                itemWidth: 100,
                itemHeight: 10,
                itemTextColor: '#999',
                itemDirection: 'left-to-right',
                itemOpacity: 1,
                symbolSize: 15,
                symbolShape: 'circle',
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemTextColor: '#000'
                        }
                    }
                ]
            }
        ]}
    />
		</motion.div>
	);

}