import React from 'react';
import { InputText } from 'primereact/inputtext';

export const AppTopbar = (props) => {
    return (
        <div className="layout-topbar clearfix">
            <span type="button" className="p-link layout-menu-button" onClick={props.onToggleMenu}>
                    {/* <InputText type="text" placeholder="Search" /> */}
                    {/* <span className="layout-topbar-search-icon pi pi-search" /> */}
            </span>
            <span>
              {/* <img class="dealer-logo" src="assets/layout/images/welcom-client-profile@3x.png" alt="toyota" /> */}
            </span>
        
            <div className="layout-topbar-icons">
               
                <button type="button" className="p-link">
                    <span className="layout-topbar-item-text">Events</span>
                    <span className="layout-topbar-icon pi pi-bell" />
                    <span className="layout-topbar-badge">5</span>
                </button>
                <button type="button" className="p-link">
                    <span className="layout-topbar-item-text">Settings</span>
                    <span className="layout-topbar-icon pi pi-cog" />
                </button>
                <button type="button" className="p-link layout-menu-button" onClick={props.onToggleMenu}>
                    <span className="pi pi-shopping-cart" />
                </button>
            </div>
            <div className="layout-topbar-image">
                <div type="top-image" className="p-link top-image-box">
                  <img class="dealer-logo" src="assets/layout/images/welcom-client-profile@3x.png" alt="toyota" />
                </div>
            </div>
        </div>
    );
}
