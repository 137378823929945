import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { MultiSelect } from 'primereact/multiselect';
import { CustomerService } from '../../../service/CustomerService';
import { Button } from 'primereact/button';
import { Modal } from '../../_campaign/modal/modal';
import { ModalBase } from '../../_campaign/modal-base/modal-base'
import { motion } from "framer-motion"


import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams,
    useRouteMatch
  } from "react-router-dom";
  
// style
import './inventory.scss'
export const Inventory = () => {

const representatives = [
    { name: "Amy Elsner", image: 'amyelsner.png' },
    { name: "Anna Fali", image: 'annafali.png' },
    { name: "Asiya Javayant", image: 'asiyajavayant.png' },
    { name: "Bernardo Dominic", image: 'bernardodominic.png' },
    { name: "Elwin Sharvill", image: 'elwinsharvill.png' },
    { name: "Ioni Bowcher", image: 'ionibowcher.png' },
    { name: "Ivan Magalhaes", image: 'ivanmagalhaes.png' },
    { name: "Onyama Limba", image: 'onyamalimba.png' },
    { name: "Stephen Shaw", image: 'stephenshaw.png' },
    { name: "XuXue Feng", image: 'xuxuefeng.png' }
];

const [isShowing, setIsShowing] = useState(false);

function toggle() {
    setIsShowing(!isShowing);
}

  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [customers, setCustomers] = useState(null);
  const [selectedRepresentative, setSelectedRepresentative] = useState(null);
  const [lazyParams, setLazyParams] = useState({
      first: 0,
      rows: 10,
      page: 1,
  });

  const dt = useRef(null);

  const customerService = new CustomerService();

  let loadLazyTimeout = null;

  useEffect(() => {
      loadLazyData();
  },[lazyParams]) // eslint-disable-line react-hooks/exhaustive-deps

  const loadLazyData = () => {
      setLoading(true);

      if (loadLazyTimeout) {
          clearTimeout(loadLazyTimeout);
      }

      //imitate delay of a backend call
      loadLazyTimeout = setTimeout(() => {
          customerService.getInventory({lazyEvent: JSON.stringify(lazyParams)}).then(data => {
            //   setTotalRecords(data.totalRecords);
              setCustomers(data.used);
              setLoading(false);
          });
      }, Math.random() * 1000 + 250);
  }

  const onPage = (event) => {
      let _lazyParams = { ...lazyParams, ...event };
      setLazyParams(_lazyParams);
  }

  const onSort = (event) => {
      let _lazyParams = { ...lazyParams, ...event };
      setLazyParams(_lazyParams);
  }

  const onFilter = (event) => {
      let _lazyParams = { ...lazyParams, ...event };
      _lazyParams['first'] = 0;
      setLazyParams(_lazyParams);
  }

  const representativeBodyTemplate = (rowData) => {
      return (
          <React.Fragment>
         
            {/* break */}
            <motion.div 
               whileHover={{ scale: 1.1 }}
               whileTap={{ scale: 0.9 }}
            className="img-org p-col-2">
              <img className="img-thumb" alt={rowData.Description} src={rowData.imageUrl} onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} style={{ verticalAlign: 'middle' }} />
              </motion.div>
              <div className="img-text p-col">
                <h2 className="img-text-cont">{rowData.Description}</h2>
                <p className="img-text-cont">
                 Avg-Stock: <strong>{rowData.stockNumber}</strong>
                </p>
                <p className="img-text-cont">
                Promote: <strong>{rowData.price}</strong>
                </p>
              </div>
          </React.Fragment>
      );
  }

  const countryBodyTemplate = (rowData) => {
      return (
            isShowing,toggle,
          <React.Fragment>
              <Button label='Promote' onClick={toggle}/>
          </React.Fragment>
      );
  }

  const onRepresentativesChange = (e) => {
    dt.current.filter(e.value, 'representative.name', 'in');
    setSelectedRepresentative(e.value);
}

  const representativesItemTemplate = (option) => {
    return (
        <div className="p-multiselect-representative-option">
            {/* <img alt={option.name} src={`showcase/demo/images/avatar/${option.image}`} onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} width={32} style={{ verticalAlign: 'middle' }} /> */}
            <span className="image-text">{option.customers.used}</span>
        </div>
    );
}


  const representativeFilter = <MultiSelect value={selectedRepresentative} options={representatives} filter itemTemplate={representativesItemTemplate} onChange={onRepresentativesChange} optionLabel="name" optionValue="name" placeholder="All" className="p-column-filter" />;
//   let { path, url } = useRouteMatch();

  return (
      <div style={{height: '100%'}}>
            <Switch>
            <Route
                render={({ match: { url } }) => (
                <>
                <Route  component={ModalBase} path={`${url}/`}>
                <Modal
                isShowing={isShowing}
                hide={toggle}
                />
                </Route>
                <Route path={`${url}/1`} component={ModalBase} />
                </> 
                )}
            />  
        </Switch>
          <div className="content-section implementation">
              <div className="card stretch">
                  <DataTable ref={dt} value={customers} lazy
                      paginator first={lazyParams.first} rows={100} totalRecords={totalRecords} onPage={onPage}
                      onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
                      onFilter={onFilter} filters={lazyParams.filters} loading={loading}>
                      <Column field="Description" body={representativeBodyTemplate} filter filterElement={representativeFilter} />
                      <Column field="Description"  body={countryBodyTemplate} filter filterElement={representativeFilter} />
                      {/* <Column field="company" sortable filter header="Company" filterPlaceholder="Search by company" /> */}
                  </DataTable>
              </div>
          </div>
      </div>
  );

}
               

