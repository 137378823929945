import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { Route, } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { BrowserRouter, Router, Switch, Redirect, useLocation } from 'react-router-dom';


import { AppTopbar } from './AppTopbar';
// import { AppFooter } from './AppFooter';
import { AppMenu } from './AppMenu';
import { AppProfile } from './AppProfile';

import { Campaign } from './pages/campaign/campaign'
import { MDashboard } from './pages/m-dashboard/m-dashboard'
import { LDashboard } from './pages/l-dashboard/l-dashboard'
import { CModal } from './components/_creation/cmodal/cmodal'
import './Styles/react-router-modal.css'


import PrimeReact from 'primereact/utils';

import './Styles/theme.css';
// import 'primereact/resources/primereact.min.css';
// import 'primeicons/primeicons.css';
import './layout/icon-style/helix-icons.css'
// import 'primeflex/primeflex.css';
// import 'prismjs/themes/prism-coy.css';

import './layout/flags/flags.css';
import './layout/layout.scss';
import './App.scss';

import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import './Styles/primereact.css';


// import 'prismjs/themes/prism-coy.css';


// function setToken(userToken) {
//     sessionStorage.setItem('token', JSON.stringify(userToken));
// }

// function getToken() {
// }


export const App = () => {

    const [layoutMode, setLayoutMode] = useState('static');
    const [layoutColorMode, setLayoutColorMode] = useState('dark')
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [inputStyle, setInputStyle] = useState('outlined');
    const [ripple, setRipple] = useState(false);
    const sidebar = useRef();
    let menuClick = false;
    
    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, 'body-overflow-hidden');
        }
        else {
            removeClass(document.body, 'body-overflow-hidden');
        }
    }, [mobileMenuActive]);

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    }

    const onRipple = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value)
    }

    const onLayoutModeChange = (mode) => {
        setLayoutMode(mode)
    }

    const onColorModeChange = (mode) => {
        setLayoutColorMode(mode)
    }

    const onWrapperClick = (event) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
        menuClick = false;
    }

    const onToggleMenu = (event) => {
        menuClick = true;

        if (isDesktop()) {
            if (layoutMode === 'overlay') {
                setOverlayMenuActive(prevState => !prevState);
            }
            else if (layoutMode === 'static') {
                setStaticMenuInactive(prevState => !prevState);
            }
        }
        else {
            setMobileMenuActive(prevState => !prevState);
        }
        event.preventDefault();
    }

    const onSidebarClick = () => {
        menuClick = true;
    }

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    }

    

    const menu = [
        { label: 'New...', icon: 'hi hi-fw icon-add-more', class:'drive-li'},
        { label: 'Dashboard', icon: 'hi hi-fw icon-beat', to: '/' },
        { label: 'Operations', icon: 'hi hi-fw icon-icon-oper'}, 
        { label: 'Campaigns', icon: 'hi hi-fw icon-helix', to: '/Campaign' },
        { label: 'Attribution', icon: 'hi hi-fw icon-tree-graph', style: 'fontSize: 12px'},
        { icon: 'hi hi-fw icon-dots', style: 'fontSize: 12px'},
    ];

    const addClass = (element, className) => {
        if (element.classList)
            element.classList.add(className);
        else
            element.className += ' ' + className;
    }

    const removeClass = (element, className) => {
        if (element.classList)
            element.classList.remove(className);
        else
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }

    const isDesktop = () => {
        return window.innerWidth > 1024;
    }

    const logo = layoutColorMode === 'dark' ? 'assets/layout/images/logo.png' : 'assets/layout/images/logo.png';

    const wrapperClass = classNames('layout-wrapper', {
        'layout-overlay': layoutMode === 'overlay',
        'layout-static': layoutMode === 'static',
        'layout-static-sidebar-inactive': staticMenuInactive && layoutMode === 'static',
        'layout-overlay-sidebar-active': overlayMenuActive && layoutMode === 'overlay',
        'layout-mobile-sidebar-active': mobileMenuActive,
        'p-input-filled': inputStyle === 'filled',
        'p-ripple-disabled': ripple === false
    });

    const sidebarClassName = classNames('layout-sidebar', {
        'layout-sidebar-dark': layoutColorMode === 'dark',
        'layout-sidebar-light': layoutColorMode === 'light'
    });

    // const { token, setToken } = useToken();

    
    // if(!token) {
    //     return <LoginPage setToken={setToken} />
    // }
   

    return (
        <div className={wrapperClass} onClick={onWrapperClick}>
            <AppTopbar onToggleMenu={onToggleMenu} />
                <div ref={sidebar} className={sidebarClassName} onClick={onSidebarClick}>
                    <div className="layout-logo">
                        <img alt="Logo" src={logo} />
                    </div>
                    <AppProfile />
                    <AppMenu model={menu} onMenuItemClick={onMenuItemClick} />
                </div>
            <div className="layout-main" style={{height: '100%', position: 'absolute'}}>
                <Switch>
                    <Route exact path="/">
                        <MDashboard/>
                        <LDashboard/>
                    </Route>
                    {/* <Route path="/login" component={Userlogin} /> */}
                    <Route path="/campaign" component={Campaign} />
                </Switch>
            </div>


        </div>
    );

}

export default App;
