import axios from 'axios'

// const config = 'https://gcc.drivehelix.com/index.php'
export class CustomerService {


    getCustomers(params) {
        return axios.get('https://www.primefaces.org/data/customers',{params: params})
                .then(res => res.data)
                .then(hello => console.log('hello', hello))
 
    }

    getInventoryLocal() {
        return axios.get('data/inventory.json')
            // .then(success => console.log('success', success))
            .then(res => res.data.data);
        
    }

    getInventory(ucid) {
        const requestOptions = {
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ ucid })
        };
    
        return axios.get('https://gcc.drivehelix.com/index.php?apiKey=08B97240-59E1-4741-ACF8-458B2749B915&do=getInventory&ucid=26225', requestOptions)
            .then(res => res.data)
            // .then(success => console.log('success', success))
            
            // .then(result => localStorage.setItem('user', JSON.parse(result)['apiKey']))
            // .then(error => console.log('succces', succces))
        };
    
    
}