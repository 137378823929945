import React, { useState, useEffect, useRef } from 'react';
import { Panel } from 'primereact/panel';
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';

import "./modal-footer.scss"



export const ModalFooter = () => {

    return (
      <div className="modal-footer p-grid center  p-top p-bot">
      <div class="w-small center">
      <Link to="/1">
        <Button className="p-col space" label="NEXT" />
      </Link>
        {/* <Button className="p-col space" label="NEW" /> */}
        <Button className="p-col space" label="CANCEL" />
      </div>
  </div>
      );
  }
