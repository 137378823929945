import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import './basic-button-row.scss'

export const BasicButtonRow = () => {

    return (
    <div className="p-grid center bb p-top p-bot">
        <div class="w-small center">
          <Button className="p-col space" label="ALL" />
          <Button className="p-col space" label="NEW" />
          <Button className="p-col space" label="USED" />
        </div>
    </div>
    );
}
     
